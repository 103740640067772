<script lang="ts" setup>
import AuthLayout from "@/Layouts/AuthLayout.vue";
import { Head, Link, useForm } from "@inertiajs/vue3";
import BaseInput from "@/Components/base/BaseInput.vue";
import BaseLabel from "@/Components/base/BaseLabel.vue";
import BaseCheckbox from "@/Components/base/BaseCheckbox.vue";
import BaseButton from "@/Components/base/BaseButton.vue";
import ErrorBanner from "@/Components/ErrorBanner.vue";
import Banner from "@/Components/Banner.vue";

defineProps<{
    canResetPassword?: boolean;
    status?: string;
}>();

const form = useForm({
    email: "",
    password: "",
    remember: false,
});

const submit = () => {
    form.post(route("login"), {
        onFinish: () => {
            form.reset("password");
        },
    });
};
</script>

<template>
    <AuthLayout>
        <Head title="Log in" />

        <div class="font-mono font-extrabold leading-10 uppercase text-2xl mb-2">
            HOUSEPLAY <span class="font-normal">Login</span>
        </div>

        <Banner v-if="status" type="success">{{ status }}</Banner>

        <ErrorBanner :errors="form.errors" />

        <form @submit.prevent="submit">
            <div>
                <BaseLabel for="email">Email</BaseLabel>

                <BaseInput
                    id="email"
                    v-model="form.email"
                    autocomplete="username"
                    autofocus
                    class="mt-1 block w-full"
                    name="email"
                    required
                    type="email"
                />
            </div>

            <div class="mt-4">
                <BaseLabel for="password">Password</BaseLabel>

                <BaseInput
                    id="password"
                    v-model="form.password"
                    autocomplete="current-password"
                    class="mt-1 block w-full"
                    name="password"
                    required
                    type="password"
                />
            </div>

            <div class="flex items-center mt-4">
                <label class="flex items-center">
                    <BaseCheckbox v-model:checked="form.remember" name="remember" />
                    <span class="ms-2 text-sm text-gray-600">Remember me</span>
                </label>

                <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                    class="text-sm text-indigo-500 hover:text-indigo-700 whitespace-no-wrap no-underline hover:underline ml-auto"
                >
                    Forgot your password?
                </Link>
            </div>

            <div class="mt-4">
                <BaseButton
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                    class="w-full"
                >
                    Log in
                </BaseButton>
            </div>

            <div class="w-full text-xs text-center text-gray-700 my-6 sm:text-sm sm:my-8">
                Don't have an account?
                <Link
                    :href="route('register')"
                    class="text-sm text-indigo-500 hover:text-indigo-700 whitespace-no-wrap no-underline hover:underline ml-auto"
                >
                    Register
                </Link>
            </div>
        </form>
    </AuthLayout>
</template>
